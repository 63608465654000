import React from 'react'
import styled from 'styled-components'
import { fontSizes } from 'theme'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/Layout'
import Button from 'components/Button'

const StyledButtonSelectPlan = styled(Button.Reversed)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  height: 100%;
  width: 215px;
  font-size: ${fontSizes.m};
`

const RequestDemoButton = () => {
  return (
    <Flex pt="m" pb={{ mobile: 'm', tablet: '0' }} justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
      <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
        <StyledButtonSelectPlan as="a" href="/demo">
          Request a demo
        </StyledButtonSelectPlan>
      </Box>
    </Flex>
  )
}

RequestDemoButton.propTypes = {}

export default RequestDemoButton
