import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, radius, colors, space, fontWeights } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  SITE_LINK_PUBLISHING,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_ENGAGEMENT,
  SITE_LINK_VISTA_PAGE,
  SITE_LINK_EMPLOYEE_ADVOCACY,
} from 'consts'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'

import carouselCheckMarkIcon from 'static/images/home/carousel/check_mark.svg'
import carouselDoubleArrowRightIcon from 'static/images/home/carousel/double_arrow_right.svg'

import carouselPublish from 'static/images/home/carousel/publish/publish.webp'
import carouselPublishMobile from 'static/images/home/carousel/publish/publish_mobile.webp'
import carouselPublishPost from 'static/images/home/carousel/publish/post.svg'
import carouselPublishInstagram from 'static/images/home/carousel/publish/instagram.svg'
import carouselPublishPostSmall from 'static/images/home/carousel/publish/post_small.svg'
import carouselPublishPublishButton from 'static/images/home/carousel/publish/publish_button.svg'
import carouselPublishSchedule from 'static/images/home/carousel/publish/schedule.svg'
import carouselPublishTikTokTime from 'static/images/home/carousel/publish/tiktok_time.svg'
import carouselPublishWednesday from 'static/images/home/carousel/publish/wednesday.svg'
import carouselPublishDots from 'static/images/home/carousel/publish/dots.svg'

import carouselAnalyze from 'static/images/home/carousel/analyze/analyze.webp'
import carouselAnalyzeMobile from 'static/images/home/carousel/analyze/analyze_mobile.webp'
import carouselAnalyzeTotal from 'static/images/home/carousel/analyze/total.svg'
import carouselAnalyzeFacebook from 'static/images/home/carousel/analyze/facebook.svg'
import carouselAnalyzeDots from 'static/images/home/carousel/analyze/dots.svg'

import carouselEngage from 'static/images/home/carousel/engage/engage.webp'
import carouselEngageMobile from 'static/images/home/carousel/engage/engage_mobile.webp'
import carouselEngageMessage from 'static/images/home/carousel/engage/message.webp'
import carouselEngageSendMessage from 'static/images/home/carousel/engage/send_message.webp'

import carouselReviews from 'static/images/home/carousel/reviews/reviews.webp'
import carouselReviewsMobile from 'static/images/home/carousel/reviews/reviews_mobile.webp'
import carouselReviewsStars from 'static/images/home/carousel/reviews/stars.svg'
import carouselReviewsReply from 'static/images/home/carousel/reviews/reply.svg'
import carouselReviewsFilter from 'static/images/home/carousel/reviews/filter.svg'
import carouselReviewsDots from 'static/images/home/carousel/reviews/dots.svg'

import carouselListening from 'static/images/home/carousel/listening/listening.webp'
import carouselListeningMobile from 'static/images/home/carousel/listening/listening_mobile.webp'
import carouselListeningMedia from 'static/images/home/carousel/listening/media.svg'
import carouselListeningQuestions from 'static/images/home/carousel/listening/questions.svg'
import carouselListeningDots from 'static/images/home/carousel/listening/dots.svg'

import carouselPages from 'static/images/home/carousel/pages/pages.webp'
import carouselPagesMobile from 'static/images/home/carousel/pages/pages_mobile.webp'
import carouselPagesDotsRight from 'static/images/home/carousel/pages/dots_right.svg'
import carouselPagesDotsBottom from 'static/images/home/carousel/pages/dots_bottom.svg'
import carouselPagesBlocks from 'static/images/home/carousel/pages/blocks.webp'
import carouselPagesPalette from 'static/images/home/carousel/pages/palette.webp'

import carouselEmployeeAdvocacy from 'static/images/home/carousel/advocacy/advocacy.webp'
import carouselEmployeeAdvocacyMobile from 'static/images/home/carousel/advocacy/advocacy_mobile.webp'
import carouselEmployeeAdvocacyDots from 'static/images/home/carousel/advocacy/dots.svg'
import carouselEmployeeAdvocacyInstagram from 'static/images/home/carousel/advocacy/instagram.svg'
import carouselEmployeeAdvocacyAnalytics from 'static/images/home/carousel/advocacy/analytics.webp'
import carouselEmployeeAdvocacySocials from 'static/images/home/carousel/advocacy/socials.webp'
import carouselEmployeeAdvocacyPeople from 'static/images/home/carousel/advocacy/people.webp'
import carouselEmployeeAdvocacyEmail from 'static/images/home/carousel/advocacy/email.webp'

const StyledCarouselWrapper = styled(Flex)`
  background: linear-gradient(0deg, #0250c9 0%, #003881 100%);
`

const StyledHeaderTextWrapper = styled(Flex)`
  border-radius: ${radius.m};
  background-color: ${transparentize(0.8, colors.white)};
`

const StyledNavWrapper = styled(Box)`
  flex-shrink: 1;
  inset: auto;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
`

const StyledCarouselList = styled(Flex)`
  transform: translate3d(0px, 0px, 0px);
  top: ${space.xl};
  box-sizing: border-box;
  position: sticky;
  overflow-x: hidden;
`

const StyledCarouselListItem = styled(Flex)`
  cursor: pointer;
`

const StyledCarouselRoundedBox = styled(Box)`
  width: ${({ isPast }) => (isPast ? '28px' : '16px')};
  height: ${({ isPast }) => (isPast ? '28px' : '16px')};
  border: 2px solid ${colors.white};
  opacity: 0.7;
  border-radius: ${radius.pill};
`

const StyledCarouselCheckMarkIcon = styled(LocalImage)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
`

const StyledCarouselDivider = styled(Flex)`
  height: 40px;
  border-left: 2px ${({ isPast }) => (isPast ? 'solid' : 'dashed')} #a6c7f5;
`

const StyledVerticalImageTextWrapper = styled(Box)`
  opacity: 0.1;
  text-transform: uppercase;
  top: 88%;
  z-index: 0;
  position: absolute;
  transform: translate(-51%,-50%);
  height: 100%;
}
`

const StyledVerticalImageText = styled(Text)`
  font-size: 64px;
  color: ${colors.white};
  font-weight: ${fontWeights.extraBold};
  transform: rotate(-90deg);
`

const StyledExploreText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${COLOR_CONSTANTS.WHITE};
    text-decoration: underline;
  }
`

const StyledCarouselImagePublishPost = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `top: ${105 * coefficient}px; left: ${210 * coefficient}px; width: ${188 * coefficient}px;`}
`

const StyledCarouselImagePublishInstagram = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `top: ${54 * coefficient}px; left: ${-40 * coefficient}px; width: ${76 * coefficient}px;`}
`

const StyledCarouselImagePublishPostSmall = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `top: ${135 * coefficient}px; right: ${-50 * coefficient}px; width: ${137 * coefficient}px;`}
`

const StyledCarouselImagePublishPublishButton = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `bottom: ${60 * coefficient}px; right: ${12 * coefficient}px; width: ${198 * coefficient}px;`}
`

const StyledCarouselImagePublishSchedule = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `bottom: ${-47 * coefficient}px; right: ${-3 * coefficient}px; width: ${465 * coefficient}px;`}
`

const StyledCarouselImagePublishTikTokTime = styled(LocalImage)`
  position: absolute;
  z-index: 3;
  ${({ coefficient }) => `top: ${200 * coefficient}px; right: ${65 * coefficient}px; width: ${248 * coefficient}px;`}
`

const StyledCarouselImagePublishWednesday = styled(LocalImage)`
  position: absolute;
  z-index: 2;
  ${({ coefficient }) => `top: ${178 * coefficient}px; right: ${104 * coefficient}px; width: ${134 * coefficient}px;`}
`

const StyledCarouselImagePublishDots = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-60 * coefficient}px; left: ${-25 * coefficient}px; width: ${52 * coefficient}px;`}
`

const StyledCarouselImageAnalyzeTotalWrapper = styled(Flex)`
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  ${({ coefficient }) =>
    `bottom: ${-45 * coefficient}px; right: ${22 * coefficient}px; width: ${245 * coefficient}px; height: ${245 *
      coefficient}px;`}
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 65.94%);
  filter: drop-shadow(0px 9px 10px rgba(14, 28, 56, 0.07));
  backdrop-filter: blur(6px);
  border-radius: ${radius.xxl};
`

const StyledCarouselImageAnalyzeFacebook = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${119 * coefficient}px; left: ${-54 * coefficient}px; width: ${211 * coefficient}px;`}
`

const StyledCarouselImageAnalyzeDots = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-25 * coefficient}px; left: ${-25 * coefficient}px; width: ${116 * coefficient}px;`}
`

const StyledCarouselImageEngageMessage = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${140 * coefficient}px; left: ${-87 * coefficient}px; width: ${688 * coefficient}px;`}
`

const StyledCarouselImageEngageSendMessage = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `top: ${-20 * coefficient}px; left: ${-40 * coefficient}px; width: ${685 * coefficient}px;`}
`

const StyledCarouselImageReviewsStars = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-50 * coefficient}px; right: ${43 * coefficient}px; width: ${187 * coefficient}px;`}
`

const StyledCarouselImageReviewsReply = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${123 * coefficient}px; left: ${-26 * coefficient}px; width: ${645 * coefficient}px;`}
`

const StyledCarouselImageReviewsFilter = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `top: ${12 * coefficient}px; right: ${-33 * coefficient}px; width: ${194 * coefficient}px;`}
`

const StyledCarouselImageReviewsDots = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-25 * coefficient}px; left: ${-25 * coefficient}px; width: ${52 * coefficient}px;`}
`

const StyledCarouselImageListeningMedia = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${55 * coefficient}px; left: ${-39 * coefficient}px; width: ${298 * coefficient}px;`}
`

const StyledCarouselImageListeningQuestions = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${125 * coefficient}px; left: ${-61 * coefficient}px; width: ${320 * coefficient}px;`}
`

const StyledCarouselImageListeningDots = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-15 * coefficient}px; right: ${20 * coefficient}px; width: ${116 * coefficient}px;`}
`

const StyledCarouselImagePagesDotsRight = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${63 * coefficient}px; right: ${-14 * coefficient}px; width: ${4 * coefficient}px;`}
`

const StyledCarouselImagePagesDotsBottom = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-14 * coefficient}px; left: ${16 * coefficient}px; width: ${116 * coefficient}px;`}
`

const StyledCarouselImagePagesBlocks = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${46 * coefficient}px; left: ${-9 * coefficient}px; width: ${268 * coefficient}px;`}
`

const StyledCarouselImagePagesPalette = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `top: ${29 * coefficient}px; left: ${-18 * coefficient}px; width: ${109 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacyInstagram = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `top: ${60 * coefficient}px; left: ${-25 * coefficient}px; width: ${44.5 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacyAnalytics = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `top: ${62 * coefficient}px; right: ${-18 * coefficient}px; width: ${133.86 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacyDots = styled(LocalImage)`
  position: absolute;
  z-index: 0;
  ${({ coefficient }) => `bottom: ${-54 * coefficient}px; left: ${-25 * coefficient}px; width: ${52 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacySocials = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${-54 * coefficient}px; left: ${-32 * coefficient}px; width: ${172 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacyPeople = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) =>
    `bottom: ${-87 * coefficient}px; right: ${18 * coefficient}px; width: ${372.4 * coefficient}px;`}
`

const StyledCarouselImageEmployeeAdvocacyEmail = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  ${({ coefficient }) => `bottom: ${107 * coefficient}px; left: ${-40 * coefficient}px; width: ${228 * coefficient}px;`}
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const MAIN_IMAGE_MAX_WIDTH = 635

const { PUBLISH, ANALYZE, ENGAGE, REVIEWS, LISTENING, PAGES, ADVOCACY } = {
  PUBLISH: 'publish',
  ANALYZE: 'analyze',
  ENGAGE: 'engage',
  REVIEWS: 'reviews',
  LISTENING: 'listening',
  PAGES: 'pages',
  ADVOCACY: 'advocacy',
}

const generateImageTemplate = ({ key }) => {
  let coefficient = 1

  const el = typeof document !== 'undefined' ? document.getElementById(`${key}_main-image`) : null

  if (el) {
    coefficient = el.width / MAIN_IMAGE_MAX_WIDTH
  }

  let template = null
  if (key === PUBLISH) {
    template = (
      <Fragment>
        <StyledCarouselImagePublishPost coefficient={coefficient} src={carouselPublishPost} />
        <StyledCarouselImagePublishInstagram coefficient={coefficient} src={carouselPublishInstagram} />
        <StyledCarouselImagePublishPostSmall coefficient={coefficient} src={carouselPublishPostSmall} />
        <StyledCarouselImagePublishPublishButton coefficient={coefficient} src={carouselPublishPublishButton} />
        <StyledCarouselImagePublishSchedule coefficient={coefficient} src={carouselPublishSchedule} />
        <StyledCarouselImagePublishTikTokTime coefficient={coefficient} src={carouselPublishTikTokTime} />
        <StyledCarouselImagePublishWednesday coefficient={coefficient} src={carouselPublishWednesday} />
        <StyledCarouselImagePublishDots coefficient={coefficient} src={carouselPublishDots} />
      </Fragment>
    )
  } else if (key === ANALYZE) {
    template = (
      <Fragment>
        <StyledCarouselImageAnalyzeTotalWrapper coefficient={coefficient}>
          <LocalImage src={carouselAnalyzeTotal} width={`${197 * coefficient}px`} />
        </StyledCarouselImageAnalyzeTotalWrapper>
        <StyledCarouselImageAnalyzeFacebook coefficient={coefficient} src={carouselAnalyzeFacebook} />
        <StyledCarouselImageAnalyzeDots coefficient={coefficient} src={carouselAnalyzeDots} />
        <Box
          display={{ mobile: 'none', tablet: 'block' }}
          bg={COLOR_CONSTANTS.WHITE}
          width={`${49 * coefficient}px`}
          height={`${49 * coefficient}px`}
          position="absolute"
          borderRadius={radius.pill}
          right={`${-227 * coefficient}px`}
          bottom={`${-70 * coefficient}px`}
        />
      </Fragment>
    )
  } else if (key === ENGAGE) {
    template = (
      <Fragment>
        <StyledCarouselImageEngageMessage coefficient={coefficient} src={carouselEngageMessage} />
        <StyledCarouselImageEngageSendMessage coefficient={coefficient} src={carouselEngageSendMessage} />
      </Fragment>
    )
  } else if (key === REVIEWS) {
    template = (
      <Fragment>
        <StyledCarouselImageReviewsStars coefficient={coefficient} src={carouselReviewsStars} />
        <StyledCarouselImageReviewsFilter coefficient={coefficient} src={carouselReviewsFilter} />
        <StyledCarouselImageReviewsReply coefficient={coefficient} src={carouselReviewsReply} />
        <StyledCarouselImageReviewsDots coefficient={coefficient} src={carouselReviewsDots} />
      </Fragment>
    )
  } else if (key === LISTENING) {
    template = (
      <Fragment>
        <StyledCarouselImageListeningMedia coefficient={coefficient} src={carouselListeningMedia} />
        <StyledCarouselImageListeningQuestions coefficient={coefficient} src={carouselListeningQuestions} />
        <StyledCarouselImageListeningDots coefficient={coefficient} src={carouselListeningDots} />
      </Fragment>
    )
  } else if (key === PAGES) {
    template = (
      <Fragment>
        <StyledCarouselImagePagesDotsRight coefficient={coefficient} src={carouselPagesDotsRight} />
        <StyledCarouselImagePagesDotsBottom coefficient={coefficient} src={carouselPagesDotsBottom} />
        <StyledCarouselImagePagesBlocks coefficient={coefficient} src={carouselPagesBlocks} />
        <StyledCarouselImagePagesPalette coefficient={coefficient} src={carouselPagesPalette} />
      </Fragment>
    )
  } else if (key === ADVOCACY) {
    template = (
      <Fragment>
        <StyledCarouselImageEmployeeAdvocacyDots coefficient={coefficient} src={carouselEmployeeAdvocacyDots} />
        <StyledCarouselImageEmployeeAdvocacyInstagram
          coefficient={coefficient}
          src={carouselEmployeeAdvocacyInstagram}
        />
        <StyledCarouselImageEmployeeAdvocacyAnalytics
          coefficient={coefficient}
          src={carouselEmployeeAdvocacyAnalytics}
        />
        <StyledCarouselImageEmployeeAdvocacySocials coefficient={coefficient} src={carouselEmployeeAdvocacySocials} />
        <StyledCarouselImageEmployeeAdvocacyPeople coefficient={coefficient} src={carouselEmployeeAdvocacyPeople} />
        <StyledCarouselImageEmployeeAdvocacyEmail coefficient={coefficient} src={carouselEmployeeAdvocacyEmail} />
      </Fragment>
    )
  }
  return template
}

const CAROUSEL_MENU_ITEMS = [
  { key: PUBLISH, name: 'Publishing' },
  { key: ENGAGE, name: 'Engagement' },
  { key: ANALYZE, name: 'Analytics' },
  { key: LISTENING, name: 'Listening' },
  { key: PAGES, name: 'Link in bio' },
  { key: REVIEWS, name: 'Reputation' },
  { key: ADVOCACY, name: 'Advocacy' },
]

const CAROUSEL_SLIDER_ITEMS = [
  {
    key: PUBLISH,
    text: `Find, collaborate, schedule and boost content for all your social channels. Visually schedule and preview your social media posts.`,
    mainImage: carouselPublish,
    mainImageMobile: carouselPublishMobile,
    textImage: 'Publish',
    exploreUrl: SITE_LINK_PUBLISHING,
  },
  {
    key: ENGAGE,
    text: `Build relationships with your followers, and easily manage your social media messages, comments, and reviews in one place.`,
    mainImage: carouselEngage,
    mainImageMobile: carouselEngageMobile,
    textImage: 'Engage',
    exploreUrl: SITE_LINK_ENGAGEMENT,
  },
  {
    key: ANALYZE,
    text: `Measure and report on the performance of your social media efforts. Create and customize simple, actionable reports that reveal what’s working and isn’t.`,
    mainImage: carouselAnalyze,
    mainImageMobile: carouselAnalyzeMobile,
    textImage: 'Analyze',
    exploreUrl: SITE_LINK_ANALYTICS,
  },

  {
    key: LISTENING,
    text: `Vista Social Listening tools help brands keep track of conversations that matter to them through timely alerts and sophisticated filtering.`,
    mainImage: carouselListening,
    mainImageMobile: carouselListeningMobile,
    textImage: 'Listening',
    exploreUrl: SITE_LINK_LISTENING,
  },
  {
    key: PAGES,
    text: `Use Vista Page to create a stunning fully-customizable link in bio and landing pages for your business or brand. Drive traffic from social networks, collect payments, generate leads, get appointments, track clicks, and more.`,
    mainImage: carouselPages,
    mainImageMobile: carouselPagesMobile,
    textImage: 'Link in bio',
    exploreUrl: SITE_LINK_VISTA_PAGE,
  },
  {
    key: REVIEWS,
    text: `Strengthen online reputation with review management features such as reporting, alerts, and the ability to respond!`,
    mainImage: carouselReviews,
    mainImageMobile: carouselReviewsMobile,
    textImage: 'Reviews',
    exploreUrl: SITE_LINK_REVIEWS,
  },
  {
    key: ADVOCACY,
    text: `Amplify your social presence with an employee advocacy. Your employees can easily engage with your content.`,
    mainImage: carouselEmployeeAdvocacy,
    mainImageMobile: carouselEmployeeAdvocacyMobile,
    textImage: 'Advocacy',
    exploreUrl: SITE_LINK_EMPLOYEE_ADVOCACY,
  },
]

const CarouselVerticalSection = () => {
  const [activeCarouselStep, setActiveCarouselStep] = useState(CAROUSEL_MENU_ITEMS[0])
  const [, setRerenderFlag] = useState(0)

  useEffect(() => {
    const EL = (sel, par) => (par || document).querySelector(sel)
    const ELS = (sel, par) => (par || document).querySelectorAll(sel)

    const carouselMenuItems = ELS('.carousel-menu-item')

    const switchCarouselSlide = (entry) => {
      const slide = EL(entry.dataset.reveal)

      let activeId = null

      carouselMenuItems.forEach((menuItem) => {
        menuItem.classList.toggle('is-active', menuItem === slide)
        if (menuItem.classList.contains('is-active')) {
          activeId = menuItem.id
        }
      })

      if (activeId) {
        setActiveCarouselStep({ ...CAROUSEL_MENU_ITEMS.find(({ key }) => key === activeId) })
      }
    }

    const inViewport = (entries) =>
      entries.forEach((entry) => entry.isIntersecting && switchCarouselSlide(entry.target))

    ELS('[data-reveal]').forEach((el) => {
      const observer = new IntersectionObserver(inViewport, {
        rootMargin: '-50% 0px -50% 0px',
        threshold: 0,
      })
      observer.observe(el)
    })
  }, [])

  const resizeUpdate = () => {
    setRerenderFlag(new Date().getTime() + Math.floor(Math.random() * 1000))
  }

  useEffect(() => {
    window.addEventListener('resize', resizeUpdate)

    return () => {
      window.removeEventListener('resize', resizeUpdate)
    }
  }, [])

  const handleClickCarouselMenuItem = ({ key }) => {
    const el = typeof document !== 'undefined' ? document.getElementById(`${key}_content`) : null
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <StyledCarouselWrapper flexDirection="column" id="carousel" py="xl">
      <Container
        py="l"
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Text color="white" fontSize="l">
          Explore our features, and achieve the results you didn't think were possible
        </Text>
        <Flex flexDirection="column">
          <Text fontWeight="bold" fontSize="3xl" color="white">
            Complete all-in-one solution, loaded
          </Text>
          <Flex>
            <StyledHeaderTextWrapper fontWeight="bold" fontSize="3xl" color="white">
              with powerful features
            </StyledHeaderTextWrapper>
          </Flex>
        </Flex>
        <Flex>
          <StyledNavWrapper width="40%" px="l" pt="xl" display={{ mobile: 'none', tablet: 'block' }}>
            <StyledCarouselList pt="m" flexDirection="column" id="carousel-menu">
              {CAROUSEL_MENU_ITEMS.map(({ key, name }, index) => {
                const foundIndex = CAROUSEL_MENU_ITEMS.findIndex(({ key }) => key === activeCarouselStep.key)
                const isActive = index === foundIndex
                const isPast = index < foundIndex
                return (
                  <StyledCarouselListItem
                    key={key}
                    id={key}
                    className={`carousel-menu-item ${isActive ? 'is-active' : ''}`}
                    flexDirection="column"
                    onClick={() => {
                      handleClickCarouselMenuItem({ key })
                    }}
                  >
                    <Flex alignItems="center" height="48px">
                      <Flex alignItems="center" justifyContent="center" width="28px" height="28px" position="relative">
                        {(isPast || isActive) && (
                          <StyledCarouselCheckMarkIcon
                            src={carouselCheckMarkIcon}
                            className="carousel-item-menu_check_mark"
                          />
                        )}
                        <StyledCarouselRoundedBox className="carousel-item-menu_rounded_box" isPast={isPast} />
                      </Flex>

                      <Text
                        className="carousel-item-menu_text"
                        ml="m"
                        fontWeight="500"
                        fontSize="xxl"
                        color="white"
                        opacity={0.7}
                      >
                        {name}
                      </Text>
                    </Flex>

                    {index + 1 !== CAROUSEL_MENU_ITEMS.length && (
                      <Flex my="xxs" alignItems="center" justifyContent="center" width="28px" height="28px">
                        <StyledCarouselDivider isPast={isPast} />
                      </Flex>
                    )}
                  </StyledCarouselListItem>
                )
              })}
            </StyledCarouselList>
          </StyledNavWrapper>

          <Flex flexDirection="column" width={{ mobile: '100%', tablet: '60%' }} pt="m">
            <Flex flexDirection="column" className="slides">
              {CAROUSEL_SLIDER_ITEMS.map(({ key, text, mainImage, mainImageMobile, textImage, exploreUrl }, index) => {
                const ImageTemplate = generateImageTemplate({ key })
                const paddingTop = index === 0 ? 'xl' : 'xxl'
                return (
                  <Flex
                    key={key}
                    flexDirection="column"
                    id={`${key}_content`}
                    data-reveal={`#${key}`}
                    pt={{ mobile: 'l', tablet: paddingTop }}
                    mt={index === 0 ? '0' : 'm'}
                    pb={index !== CAROUSEL_MENU_ITEMS.length - 1 ? 'xl' : '0'}
                  >
                    <Flex
                      mb="m"
                      justifyContent="space-between"
                      alignItems="center"
                      display={{ mobile: 'flex', tablet: 'none' }}
                    >
                      <Text color="white" fontSize="xxl" fontWeight="bold">
                        {textImage}
                      </Text>
                      <Flex alignItems="center">
                        <StyledExploreText
                          as="a"
                          href={exploreUrl}
                          fontWeight="medium"
                          fontSize="l"
                          color={COLOR_CONSTANTS.SALT}
                        >
                          Explore
                        </StyledExploreText>
                        <LocalImage src={carouselDoubleArrowRightIcon} />
                      </Flex>
                    </Flex>
                    <Flex
                      maxWidth={{ mobile: '635px', tablet: '425px' }}
                      width="100%"
                      zIndex="1"
                      flexDirection="column"
                    >
                      <Text color="white" fontSize="l">
                        {text}
                      </Text>
                    </Flex>

                    <StyledFlex
                      display={{ mobile: 'flex', tablet: 'none' }}
                      mt="l"
                      position="relative"
                      maxWidth={`${MAIN_IMAGE_MAX_WIDTH}px`}
                      width="100%"
                      onClick={() => {
                        window.open(exploreUrl, '_self')
                      }}
                    >
                      <LocalImage src={mainImageMobile} zIndex="1" width="100%" loading="lazy" alt={text} />
                    </StyledFlex>

                    <StyledFlex
                      display={{ mobile: 'none', tablet: 'flex' }}
                      mt="l"
                      position="relative"
                      maxWidth={`${MAIN_IMAGE_MAX_WIDTH}px`}
                      width="100%"
                      onClick={() => {
                        window.open(exploreUrl, '_self')
                      }}
                    >
                      <LocalImage
                        id={`${key}_main-image`}
                        src={mainImage}
                        zIndex="1"
                        width="100%"
                        loading="lazy"
                        alt={text}
                        onLoad={() => {
                          resizeUpdate()
                        }}
                      />
                      <StyledVerticalImageTextWrapper>
                        <StyledVerticalImageText>{textImage}</StyledVerticalImageText>
                      </StyledVerticalImageTextWrapper>
                      <Box position="absolute" width="100%" height="100%">
                        {ImageTemplate}
                      </Box>
                      <Flex
                        position="absolute"
                        width="270px"
                        height="270px"
                        bg="primary"
                        borderRadius={radius.pill}
                        top="-182px"
                        right="-94px"
                        zIndex="0"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <StyledExploreText
                          as="a"
                          href={exploreUrl}
                          fontWeight="medium"
                          fontSize="l"
                          color={COLOR_CONSTANTS.SALT}
                        >
                          Explore
                        </StyledExploreText>
                        <LocalImage src={carouselDoubleArrowRightIcon} />
                      </Flex>
                      <Box
                        bg={COLOR_CONSTANTS.SALT}
                        width="44px"
                        height="44px"
                        position="absolute"
                        borderRadius={radius.pill}
                        right="-65px"
                        top="87px"
                      />
                    </StyledFlex>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </StyledCarouselWrapper>
  )
}

export default CarouselVerticalSection
